import defaultSettings from '$lib/data/defaultSettings.json';

export type Settings = {
  name: string;
  gojiraCardSets: GojiraCardSets;
  baseCardSets: BaseCardSets;
};

export type Gojira = {
  name: string;
  base: number;
  attributes: number[];
};

// export type Card = {
//   // type: string;
//   attack: number;
//   defense: number;
// };
export type GojiraCard = {attack: number; defense: number; protection: number; strength: number; type?: string};
export type BaseCard = {attack: number; defense: number; protection: number; strength: number; type?: string};

// export type GojiraCardSets = {
//   index: number; // same as index in array, not needed
//   type: string;
//   set: {
//     index: number; // same as index in array, not needed
//     type: string; // duplicate, same on all hierarchy
//     cards: Card[]; // a set can give multiple card, but they have the same type
//   }[]; // list of card that is picked for a particular set
// }[]; // list of each set, set 0 is back, set 1, is mouth, etc...

export type GojiraCardSet = {
  type: string;
  set: GojiraCard[][];
};

export type GojiraCardSets = GojiraCardSet[]; // list of each set, set 0 is back, set 1, is mouth, etc...

// export type BaseCardSets = {
//   index: number; // same as index in array, not needed
//   cards: BaseCard[];
// }[]; // list of the different set a gojira can be, each card can have different type, each type can only be used in a row

export type BaseCardSets = BaseCard[][]; // list of the different set a gojira can be, each card can have different type, each type can only be used in a row

export type FullGojira = {
  gojira: Gojira;
  settings: Settings;
};

export type FightProposition = {
  fullGojira: FullGojira;
  cardsPlayed: CardsPlayed<{attributeIndex: number; cardIndex: number}, CardType>;
};

export type CardType = number; // {type} ?

export type CardsInPlay<T = CardType> = [T, T, T, T, T];

export type CardsPlayed<T = CardType, U = CardType> = {
  gojiraCards: CardsInPlay<T>;
  baseCards: CardsInPlay<U>;
};

export const defaultFullGojira = {
  gojira: {
    name: 'default',
    base: 0,
    attributes: [0, 0, 0, 0, 0],
  },
  settings: defaultSettings,
};

// export function encodeGojira(gojira: Gojira): string {
//   return '';
// }

// export function decodeGojira(gojiraStr: string): Gojira {
//   return defaultGojira;
// }

// export function decodeGojiraCardSets(gojiraStr: string): GojiraCardSets {
//   return defaultSettings.gojiraCardSets;
// }

// export function decodeBaseCardSets(gojiraStr: string): BaseCardSets {
//   return defaultSettings.baseCardSets;
// }

export function encodeFight(fightProposition: FightProposition): string {
  const compressed = LZString.compressToBase64(JSON.stringify(fightProposition));
  return compressed;
}
export function decodeFight(str: string): FightProposition {
  const decompressed = LZString.decompressFromBase64(str);
  const original = JSON.parse(decompressed);
  return original as FightProposition;
}

export function encodeFullGojira(fullGojira: FullGojira): string {
  const compressed = LZString.compressToBase64(JSON.stringify(fullGojira));
  return compressed;
}

// export function decodeSettings(str: string): Settings {
//   return {
//     name: '', // TODO
//     gojiraCardSets: decodeGojiraCardSets(''),
//     baseCardSets: decodeBaseCardSets(''),
//   };
// }

export function decodeFullGojira(str: string): FullGojira {
  const decompressed = LZString.decompressFromBase64(str);
  const original = JSON.parse(decompressed);

  return original as FullGojira;
}

function areArraysEqual(a: (string | number)[], b: (string | number)[]) {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] != b[i]) {
      return false;
    }
  }
  return true;
}

export function areBaseCardsEqual(a: BaseCard, b: BaseCard): boolean {
  return a.attack == b.attack && a.defense == b.defense;
}

export function areGojiraCardsEqual(a: GojiraCard, b: GojiraCard): boolean {
  return a.attack == b.attack && a.defense == b.defense;
}

function areAnyArraysEqual<T>(a: T[], b: T[], equality: (a: T, b: T) => boolean) {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (!equality(a[i], b[i])) {
      return false;
    }
  }
  return true;
}

export function areBaseCardSetsEqual(a: BaseCardSets, b: BaseCardSets): boolean {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (!areAnyArraysEqual(a[i], b[i], areBaseCardsEqual)) {
      return false;
    }
  }
  return true;
}

export function areGojiraCardSetEqual(a: GojiraCardSet, b: GojiraCardSet): boolean {
  if (a.type !== b.type) {
    return false;
  }
  if (a.set.length !== b.set.length) {
    return false;
  }
  for (let i = 0; i < a.set.length; i++) {
    if (!areAnyArraysEqual(a.set[i], b.set[i], areGojiraCardsEqual)) {
      return false;
    }
  }
  return true;
}

export function areGojiraCardSetsEqual(a: GojiraCardSets, b: GojiraCardSets): boolean {
  return areAnyArraysEqual(a, b, areGojiraCardSetEqual);
}

export function areSettingsEqual(a: Settings, b: Settings): boolean {
  return (
    a.name == b.name &&
    areBaseCardSetsEqual(a.baseCardSets, b.baseCardSets) &&
    areGojiraCardSetsEqual(a.gojiraCardSets, b.gojiraCardSets)
  );
}

export function areFullGojiraEqual(a: FullGojira, b: FullGojira): boolean {
  return areGojirasEqual(a.gojira, b.gojira) && areSettingsEqual(a.settings, b.settings);
}

export function areGojirasEqual(a: Gojira, b: Gojira): boolean {
  return a.name === b.name && a.base === b.base && areArraysEqual(a.attributes, b.attributes);
}

export function clone<T>(obj: T): T {
  const cloned = JSON.parse(JSON.stringify(obj));
  nullToUndefined(cloned);
  return cloned as T;
}

function nullToUndefined(obj: any) {
  if (obj) {
    if (typeof obj === 'object') {
      if (obj.length) {
        for (let i = 0; i < obj.length; i++) {
          if (obj[i] === null) {
            obj[i] = undefined;
          } else if (typeof obj[i] === 'object') {
            nullToUndefined(obj[i]);
          }
        }
      } else {
        for (const key of Object.keys(obj)) {
          if (obj[key] === null) {
            obj[key] = undefined;
          } else if (typeof obj[key] === 'object') {
            nullToUndefined(obj[key]);
          }
        }
      }
    }
  }
}
