import type {Settings} from '$lib/gojiras';
import {areSettingsEqual, clone} from '$lib/gojiras';
import defaultSettings from '$lib/data/defaultSettings.json';
import {derived, get, writable} from 'svelte/store';

export type CurrentSettingsState = {
  inList: boolean;
  changed: boolean;
};

export type AllSettings = {
  lastUsed: string;
  list: {[name: string]: Settings};
};

function save(all: AllSettings): void {
  try {
    window.localStorage.setItem('settings', JSON.stringify(all));
  } catch (e) {
    console.error(e);
  }
}

function fetch(): AllSettings {
  let fromLocalStorage: AllSettings | undefined;
  if (typeof window !== 'undefined') {
    try {
      const str = window.localStorage.getItem('settings');
      fromLocalStorage = JSON.parse(str);
    } catch (e) {
      console.error(e);
    }
  }

  // console.log({fromLocalStorage});

  if (!fromLocalStorage || !fromLocalStorage.lastUsed) {
    fromLocalStorage = {
      lastUsed: defaultSettings.name,
      list: {
        [defaultSettings.name]: clone(defaultSettings),
      },
    };
  }

  // console.log({fromLocalStorage});
  return fromLocalStorage;
}

const all = fetch();

const $currentSettings = clone(all.list[all.lastUsed]);
const currentSettings = writable<Settings>($currentSettings);

const $allSettings: AllSettings = all;
const allSettingsStore = writable<AllSettings>($allSettings);
const allSettings = {
  subscribe: allSettingsStore.subscribe,
  delete(name: string) {
    allSettingsStore.update((v) => {
      delete v[name];
      // TODO lastUsed ?
      save(v);
      return v;
    });
  },
  saveCurrent() {
    allSettingsStore.update((v) => {
      const cloned = clone(get(currentSettings));
      // const existingSettings = v.list[cloned.name];
      // if (!existingSettings) {
      //   console.log(`adding ${cloned.name}....`);
      // } else {
      //   console.log(`edditing ${cloned.name}...`);
      // }
      v.list[cloned.name] = cloned;
      v.lastUsed = cloned.name;
      save(v);
      return v;
    });
  },
  use(name: string) {
    const all = clone(get(allSettings));
    currentSettings.update((v) => {
      return clone(all.list[name]);
    });
    allSettingsStore.update((v) => {
      v.lastUsed = name;
      save(v);
      return v;
    });
  },
  reset() {
    const state = clone(get(currentSettingsState));
    if (state.inList && state.changed) {
      const all = clone(get(allSettings));
      const current = clone(get(currentSettings));
      currentSettings.update((v) => {
        return clone(all.list[current.name]);
      });
    }
  },
  clear() {
    window.localStorage.removeItem('settings');
    allSettingsStore.set(fetch());
  },
};

const currentSettingsState = derived([allSettings, currentSettings], ([$allSettings, $currentSettings]) => {
  const existingSettings = $allSettings.list[$currentSettings.name];
  const inList = !!existingSettings;
  const changed = inList && !areSettingsEqual(existingSettings, $currentSettings);
  return {
    inList,
    changed,
  };
});

export {currentSettings, allSettings, currentSettingsState};
